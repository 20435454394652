import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy on mobile employee endpoint responsibilities.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`See Purpose.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`This document describes Information Security\\‘s requirements for
employees of Data Migrators that work outside of an office setting.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy applies to any mobile device, or endpoint computer issued by
Data Migrators or used for Data Migrators business which contains
stored data owned by Data Migrators.`}</p>
    <h2>{`Policy`}</h2>
    <p>{`All employees shall assist in protecting devices issued by Data Migrators or storing Data Migrators data. Mobile devices are defined to
include desktop systems in a telework environment, laptops, PDAs, and
cell phones.`}</p>
    <p>{`Users are expressly forbidden from storing Data Migrators data on
devices that are not issued by Data Migrators, such as storing
Data Migrators email on a personal cell phone or PDA.`}</p>
    <p>{`4.1 Anti-Virus, Secunia CSI and Endpoint Security Software`}</p>
    <p>{`Data Migrators will issue computers with Secunia, Anti-virus and
Endpoint security installed. Employees are to notify the security
department immediately if they see error messages for these products.
Employees shall run on online malware scanner at least once a month for
a “second opinion”, see `}<em parentName="p">{`MS Endpoint Privacy & Security Guidelines`}</em>{` for
recommended scanners.`}</p>
    <p>{`4.2 Browser Add-ons`}</p>
    <p>{`In general, Data Migrators does not recommend using Browser Add-ons,
however we do not forbid the use of these tools if they enhance
productivity. After installing a Browser Add-on, employees shall run a
browser testing tool. See `}<em parentName="p">{`MS Endpoint Privacy & Security Guidelines`}</em>{`
for recommended testing tools.`}</p>
    <h2>{`Policy Compliance`}</h2>
    <h3>{`Compliance Measurement`}</h3>
    <p>{`The Infosec team will verify compliance to this policy through various
methods, including but not limited to, periodic walk-thrus, video
monitoring, business tool reports, internal and external audits, and
feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <ul>
      <li parentName="ul">{`MS Endpoint Privacy & Security Guidelines`}</li>
    </ul>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`None.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      